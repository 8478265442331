// App.js
import React, { useEffect } from 'react';
import { Route, Routes  } from 'react-router-dom';
import PageNotFound from "./components/Config/PageNotFound";
import UnAuth from './components/Config/UnAuth';
import Login    from './components/Auth/login';
import Register from './components/Auth/register';
import Welcome  from './components/welcome';
import Create  from './components/Chambre/Create';
import Details  from './components/Chambre/Details';

import NewPhoto  from './components/Photos/Create';
import Photos  from './components/Photos/Details';

import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
	
    useEffect(() => {	
	    if (!window.Android) {  
		    localStorage.clear(); 
		    sessionStorage.clear(); 
		    window.location.href = 'https://fleetbo.iO';	
		} 
	}, []);

    return (
		<div className="App">
		  <Routes>
			{/* Racine */}
			<Route path="/" element={<Welcome />} />
            <Route path="/welcome" element={<Welcome />} />

			{/* Authentification */}
			<Route path="/login" element={<Login />} />
			<Route path="/register" element={<Register />} />
			<Route path="/un" element={<UnAuth />} />
			
			<Route path="/create" element={<Create />} />
			<Route path="/details/:id" element={<Details />} />

			<Route path="/add-photo/:id" element={<NewPhoto />} />
			<Route path="/photos/:id" element={<Photos />} />

			
			{/* Page non trouvée */}
			<Route path="*" element={<PageNotFound />} />
		  </Routes>
		</div>
    );
};

export default App;
