import React, { useEffect, useState } from 'react';
import {  Link, useNavigate, useParams  } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';


const Details = () => {

    const [isLoading, setIsLoading]       = useState(true); 
	const [appInfo, setAppInfo]           = useState(null);
	const [message, setMessage]           = useState("");
	const [documentData, setDocumentData] = useState(
		JSON.parse(localStorage.getItem('document')) || null
	);

	const navigate = useNavigate();
	const { id } = useParams();

	const handleImageLoad = () => {
		// Une fois que l'image est chargée, vous pouvez vérifier si toutes les ressources sont prêtes
		setIsLoading(false);
	};


    // Fonction appelée depuis Kotlin pour afficher les documents
	window.getDocumentss = (jsonString) => {
		try {
			const fetchedDocument = JSON.parse(jsonString); // Convertir la chaîne JSON en objet
			console.log("Document reçu :", fetchedDocument);
			setDocumentData(fetchedDocument); // Mettre à jour l'état avec les documents reçus
			setMessage("HELLLLLOOO"); 
			setIsLoading(false); // Mettre à jour le statut de chargement
		} catch (error) {
			console.error("Erreur lors de l'analyse du JSON :", error);
		}
    };

	// Fonction appelée depuis Kotlin pour afficher les documents
	window.getDocument = (jsonString) => {
		try {
			// Convertir la chaîne JSON reçue en objet
			const fetchedDocument = JSON.parse(jsonString);
			console.log("Documents reçus :", fetchedDocument);
			setMessage(id); 

			// Récupérer les documents précédemment stockés dans LocalStorage (si existants)
			const storedDocument = localStorage.getItem("document");

			if (storedDocument) {
				// Si des documents sont déjà stockés, les comparer avec les nouveaux
				const parsedStoredDocument = JSON.parse(storedDocument);

				// Si les documents sont identiques, ne pas mettre à jour
				if (JSON.stringify(parsedStoredDocument) === JSON.stringify(fetchedDocument)) {
					setDocumentData(parsedStoredDocument);
					setMessage("Les documents n'ont pas changé"); 
					console.log("Les documents n'ont pas changé.");
					setIsLoading(false); // Arrêter le chargement
					return;
				}

				// Si les documents sont différents, les mettre à jour
				console.log("Les documents ont changé, mise à jour en cours.");
				setMessage("Les documents ont changé"); 
				localStorage.setItem("document", JSON.stringify(fetchedDocument));
				setDocumentData(fetchedDocument); // Mettre à jour les documents affichés
				setIsLoading(false);
			} else {
				// Si c'est la première connexion, créer le LocalStorage et mettre à jour les documents
				console.log("Première connexion : création de LocalStorage.");
				setMessage("Première"); 
				localStorage.setItem("documents", JSON.stringify(fetchedDocument));
				setDocumentData(fetchedDocument); // Mettre à jour les documents affichés
				setIsLoading(false);
			}
		} catch (error) {
			// Gérer les erreurs JSON ou autres
			console.error("Erreur lors de l'analyse du JSON :", error);
			setMessage("Erreur", error); 
			setIsLoading(false); // Arrêter le chargement en cas d'erreur
		}
	};
	

	useEffect(() => {
			// Fonction principale pour initialiser les données
				const data = localStorage.getItem('AppInfo');
				if (data) {
					try {
						// Parsez les données JSON récupérées
						const parsedData = JSON.parse(data);
						setAppInfo(parsedData);
		
						if (parsedData.logged) {
							if (window.Android && typeof window.Android.getDataFromDoc=== 'function') {
								const documentPath = `chambres/${id}`; 
								window.Android.getDataFromDoc(documentPath);
							} else {
								navigate('/un'); // Rediriger si l'API Android n'est pas disponible
							}
						} else {
							navigate('/login'); // Rediriger si l'utilisateur n'est pas connecté
						}
					} catch (error) {
						console.error("Erreur lors de l'analyse des données localStorage:", error);
						//navigate('/un'); // Rediriger en cas d'erreur de parsing
					}
				} else {
					// Rediriger si aucune donnée n'est présente
					navigate('/un');
				}
		
				// Mettre à jour le statut de chargement dans tous les cas
				setIsLoading(false);
	
	}, [navigate, id]);
	

	
	// Pendant le chargement, vous pouvez afficher un indicateur visuel de chargement ou simplement retourner null
	if (isLoading) {
      return <div><><div className="loader fs-4 fw-normal text-dark p-5">
		  <Spinner animation="border" variant="danger" role="status">
			  <span className="visually-hidden text-light">Loading...</span>
		  </Spinner>
      </div></> </div>;
    }

    return (
        <div className='App'>
     
		    {appInfo ? (
				<>
					<nav className="fixed-top py-2">
						<div className="container d-flex align-items-center justify-content-between">
							{/* Logo et Titre */}
							<div className="d-flex align-items-center">
								<Link onClick={() => window.history.back()} className="fs-5 fw-bold text-white me-5" style={{ textDecoration: 'none'}} aria-label="User Account">
									<i className="fa-solid fs-3 fa-arrow-left"></i> <span className='ms-2 fs-3'  >Details</span> 
								</Link>
							</div>
						</div>
					</nav>

					<br /> <br /> <br /> 
					<div className='p-2'>

					    {isLoading ? (
							<div>Chargement...</div>
						) : (
							<div className="box-features bg-transparent">
							{documentData ? (
									<>
									<div className="box-features mt-4 bg-white">
										<div className="item">
											<div style={{ height: '250px' }}>
												<img 
													style={{ width: '100%', height: '100%' }} 
													className="img-room" 
													onLoad={handleImageLoad} 
													alt="item" 
													src={documentData.image}
												/>
											</div>
											<div className="col-md-12 div-details-welcome">
												<div style={{ height: '50px' }}>
													<h5 className="details1">{documentData.complement}</h5>
												</div>
												<h6 className="text-dark">
													<i className="fa-solid fa-location me-1"></i>
													{documentData.ville} - {documentData.country}
												</h6>
												<h6 className="mt-1" style={{ color: '#545454' }}>
													<i className="fa-solid fa-money-bills me-1"></i> {documentData.prix} FCFA / nuit
												</h6>
												<div>
													<Link  to={`/photos/${id}`} className="btn-start fs-6 text-center">
														Plus de photos <i className="fa-solid fa-image ms-2"></i>
													</Link>
												</div>
											</div>
										</div>
                                    </div>
									</>
							) : (
								<>
								   <div>Aucune chambre à afficher</div>
								   <div className="text-success fw-normal mt-1" style={{ height: '20px'}}>
                                    {message && <p>{message}</p>}
                                    </div>
								</>
								
							)}
							</div>
						)}
					</div>
					<Link to={`/add-photo/${id}`} ><i style={{background: '#783031', color: '#fff'}} className="fa fa-camera floating p-4 fs-3 me-1" ></i></Link>
					
			    </>
            ) : (
			    <>
				    <div className='App-Container'>
						<div className="text-center">
							<h2 className="text-danger">Erreur: Informations de l'application non disponibles</h2>
						</div>
			        </div>
				</>
            )}	   
        </div>     
    )
};

export default Details;
