import React, { useEffect, useState } from 'react';
import {  Link, useNavigate  } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';


const Welcome = () => {

    const [isLoading, setIsLoading] = useState(true);
	const [loading, setLoading] = useState(true);
	const [appInfo, setAppInfo]     = useState(null);
	const [documents, setDocuments] = useState([]);
	const navigate = useNavigate();


	const logout = () => {
		setIsLoading(true);
		setTimeout(() => {
			if (window.Android && typeof window.Android.o00011 === 'function') {
				window.Android.o00011();
				  localStorage.clear();
			} else {
				navigate('/un');
			}
		}, 1000);
	};

	const handleImageLoad = () => {
		// Une fois que l'image est chargée, vous pouvez vérifier si toutes les ressources sont prêtes
		setIsLoading(false);
	};


    // Fonction appelée depuis Kotlin pour afficher les documents
	window.handleFetchedDocuments1 = (jsonString) => {
		try {
			const fetchedDocuments = JSON.parse(jsonString); // Convertir la chaîne JSON en objet
			console.log("Documents reçus :", fetchedDocuments);
			setDocuments(fetchedDocuments); // Mettre à jour l'état avec les documents reçus
			setIsLoading(false); // Mettre à jour le statut de chargement
		} catch (error) {
			console.error("Erreur lors de l'analyse du JSON :", error);
		}
    };


	// Fonction appelée depuis Kotlin pour afficher les documents
	window.handleFetchedDocuments = (jsonString) => {
		try {
			// Convertir la chaîne JSON reçue en objet
			const fetchedDocuments = JSON.parse(jsonString);
			console.log("Documents reçus :", fetchedDocuments);

			// Récupérer les documents précédemment stockés dans LocalStorage (si existants)
			const storedDocuments = localStorage.getItem("documents");

			if (storedDocuments) {
				// Si des documents sont déjà stockés, les comparer avec les nouveaux
				const parsedStoredDocuments = JSON.parse(storedDocuments);

				// Si les documents sont identiques, ne pas mettre à jour
				if (JSON.stringify(parsedStoredDocuments) === JSON.stringify(fetchedDocuments)) {
					setDocuments(parsedStoredDocuments);
					console.log("Les documents n'ont pas changé.");
					setIsLoading(false); // Arrêter le chargement
					return;
				}

				// Si les documents sont différents, les mettre à jour
				console.log("Les documents ont changé, mise à jour en cours.");
				localStorage.setItem("documents", JSON.stringify(fetchedDocuments));
				setDocuments(fetchedDocuments); // Mettre à jour les documents affichés
				setIsLoading(false);
			} else {
				// Si c'est la première connexion, créer le LocalStorage et mettre à jour les documents
				console.log("Première connexion : création de LocalStorage.");
				localStorage.setItem("documents", JSON.stringify(fetchedDocuments));
				setDocuments(fetchedDocuments); // Mettre à jour les documents affichés
				setIsLoading(false);
			}
		} catch (error) {
			// Gérer les erreurs JSON ou autres
			console.error("Erreur lors de l'analyse du JSON :", error);
			setIsLoading(false); // Arrêter le chargement en cas d'erreur
		}
	};
	

	useEffect(() => {
		// Fonction principale pour initialiser les données
			const data = localStorage.getItem('AppInfo');
	
			if (data) {
				try {
					// Parsez les données JSON récupérées
					const parsedData = JSON.parse(data);
	
					if (parsedData.logged) {
						if (window.Android && typeof window.Android.getDataFromColl === 'function') {
							const collectionPath = "chambres";
							window.Android.getDataFromColl(collectionPath);
							setAppInfo(parsedData);
						} else {
							navigate('/un'); // Rediriger si l'API Android n'est pas disponible
						}
					} else {
						navigate('/login'); // Rediriger si l'utilisateur n'est pas connecté
					}
				} catch (error) {
					console.error("Erreur lors de l'analyse des données localStorage:", error);
					//navigate('/un'); // Rediriger en cas d'erreur de parsing
				}
			} else {
				// Erreur authentification potentiele fraude
				//navigate('/un');
			}
	
			// Mettre à jour le statut de chargement dans tous les cas
			setLoading(false);

	}, [navigate]);
	

	
	// Pendant le chargement, vous pouvez afficher un indicateur visuel de chargement ou simplement retourner null
	if (isLoading) {
      return <div><><div className="loader fs-4 fw-normal text-dark p-5">
		  <Spinner animation="border" variant="danger" role="status">
			  <span className="visually-hidden text-light">Loading...</span>
		  </Spinner>
      </div></> </div>;
    }

    return (
        <div className='App'>
     
		    {appInfo ? (
				<>
					<nav className="fixed-top py-2">
						<div className="container d-flex align-items-center justify-content-between">
							{/* Logo et Titre */}
							<div className="d-flex align-items-center">
								<img
									src={`${process.env.PUBLIC_URL}/logo512.jpg`}
									className="rounded-circle bg-light"
									style={{ width: '40px', height: '40px' }}
									alt="Logo"
								/>
								<h4 className="fw-bold ms-3 fs-5 mb-0 text-white">{appInfo.name}</h4>
							</div>

							{/* Bouton de déconnexion */}
							<button
								onClick={logout}
								className="btn btn-light btn-sm d-flex align-items-center justify-content-center"
								style={{ width: '25px', height: '25px', borderRadius: '50%' }}
							>
								<i className="fa-solid fa-power-off text-danger"></i>
							</button>
						</div>
					</nav>

					<br /> <br /> <br /> 
					<div className='p-2'>

					    {loading ? (
							<div><h6 className='text-danger'>Chargement...</h6></div>
						) : (
							<div className="box-features bg-transparent">
							{documents.length > 0 ? (
								documents.map((doc, index) => (
									<>
									<div className="box-features mt-4 bg-white">
										<div key={index} className="item">
											<div style={{ height: '250px' }}>
												<img 
													style={{ width: '100%', height: '100%' }} 
													className="img-room" 
													onLoad={handleImageLoad} 
													alt="item" 
													src={doc.image}
												/>
											</div>
											<div className="col-md-12 div-details-welcome">
												<div style={{ height: '50px' }}>
													<h5 className="details1">{doc.complement}</h5>
												</div>
												<h6 className="text-dark">
													<i className="fa-solid fa-location me-1"></i>
													{doc.ville} - {doc.country}
												</h6>
												<h6 className="mt-1" style={{ color: '#545454' }}>
													<i className="fa-solid fa-money-bills me-1"></i> {doc.prix} FCFA / nuit
												</h6>
												<div>
													<Link  to={`/details/${doc.id}`} className="btn-start fs-6 text-center">
													   Ouvrir <i className="fa-solid fa-plus me-2"></i>
													</Link>
												</div>
											</div>
										</div>
                                    </div>
									</>
								))
							) : (
								<>
								   <div>Aucune chambre à afficher</div>
								</>
								
							)}
							</div>
						)}
					</div>
					<Link to='/create'><i style={{background: '#783031', color: '#fff'}} className="fa fa-add floating p-4 fs-3 me-1" ></i></Link>
					
			    </>
            ) : (
			    <>
				    <div className='App-Container'>
						<div className="text-center">
							<h2 className="text-danger">Erreur: Informations de l'application non disponibles</h2>
						</div>
			        </div>
				</>
            )}	   
        </div>     
    )
};

export default Welcome;
