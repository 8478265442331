import React, { useEffect, useState } from 'react';
import {  Link, useNavigate, useParams  } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';


const Create = () => {

    const [isLoading, setIsLoading]   = useState(true);
	const [appInfo, setAppInfo]       = useState(null);

	const navigate                    = useNavigate();
    const { id }                      = useParams();

    const [imageURL, setImageURL] = useState(""); // State pour l'URL de l'image
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        image: "", // Ajouter un champ pour l'image encodée en base64
        description: "",
        Idchambre: id,
        dateCreated: new Date().toLocaleString(),
    });

    // Gérer les changements dans les champs du formulaire
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // Gérer la soumission du formulaire
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage("");

        // Vérification des champs obligatoires
        if (!formData.ville || !formData.quartier || !formData.prix) {
            setMessage("Tous les champs doivent être remplis !");
            setLoading(false);
            return;
        }

        if (!imageURL) {
            setMessage("Veuillez d'abord sélectionner une image !");
            setLoading(false);
            return;
        }

        // Vérifiez si l'interface Android et la méthode existent
        if (window.Android && typeof window.Android.ndf043 === "function") {
            const collectionPath = "photos";

            // Appel de la méthode Kotlin
            try {
                // Appel de la méthode Kotlin
                window.Android.ndf043(
                    collectionPath,
                    JSON.stringify(formData) // Convertir les données en JSON
                );
            } catch (error) {
                console.error("Erreur lors de l'appel de NewDocWithFile :", error);
                setMessage("Erreur lors de l'envoi des données.");
            }
        } else {
            console.error("La méthode NewDocWithFile n'est pas disponible.");
            setMessage("Erreur : Interface Android non disponible.");
            setLoading(false); // Désactiver le chargement
        }
    };

    // Fonction appelée par Kotlin pour retourner le résultat
    window.onNewDocWithFileResult = (success) => {
        if (success) {
            setMessage("Données envoyées avec succès !");
        } else {
            setMessage("Erreur lors de l'envoi des données.");
        }
        setLoading(false); // Désactiver le chargement
    };

    // Fonction globale pour recevoir l'image encodée en base64 depuis Kotlin
    window.displayImage = (escapedImageBase64) => {
        try {
            const decodedImageBase64 = decodeURIComponent(escapedImageBase64); // Décoder l'image
            setImageURL(decodedImageBase64); // Met à jour l'URL de l'image
            setFormData((prevData) => ({
                ...prevData,
                image: escapedImageBase64, // Ajouter l'image au formulaire
            }));
        } catch (error) {
            console.error("Erreur lors du décodage de l'image:", error);
            setMessage("Erreur lors du décodage de l'image.");
        }
    };
    

    /****   Camera  ****/
	const openPicker = () => {
        if (typeof window.Android !== 'undefined' && typeof window.Android.openImagePicker === 'function') {
            window.Android.openImagePicker(); // Appel de la fonction Kotlin pour ouvrir la caméra
        } else {
            setMessage("L'interface Android n'est pas disponible");
        }
    };

    
	useEffect(() => {
            // Récupérer les données depuis localStorage dès que le composant est monté
            const data = localStorage.getItem('AppInfo');
            setTimeout(() => {
              if (data) {
                // Parsez les données JSON récupérées
                const parsedData = JSON.parse(data);
                
                // Vérifiez la valeur de 'logged' et mettez à jour l'état
                if (parsedData.logged === true) {
                  setAppInfo(parsedData); // Mettre à jour les informations de l'app
                  setIsLoading(false);  // Pas de données, terminer le chargement
                }else {
                    setAppInfo(parsedData);
                    setIsLoading(false);
                    //navigate('/auth');
                }
              } else {
                setIsLoading(false);  // Pas de données dans localStorage
              }
            }, 400);
    }, [navigate]);


	// Pendant le chargement, vous pouvez afficher un indicateur visuel de chargement ou simplement retourner null
	if (isLoading) {
      return <div><><div className="loader fs-4 fw-normal text-dark p-5">
		  <Spinner animation="border" variant="danger" role="status">
			  <span className="visually-hidden text-light">Loading...</span>
		  </Spinner>
      </div></> </div>;
    }

    return (
        <div className='App'>
     
		    {appInfo ? (
				<>
                    <nav className="fixed-top py-2">
						<div className="container d-flex align-items-center justify-content-between">
							{/* Logo et Titre */}
							<div className="d-flex align-items-center">
                                <Link onClick={() => window.history.back()} className="fs-5 fw-bold text-white me-5" style={{ textDecoration: 'none'}} aria-label="User Account">
                                        <i className="fa-solid fs-3 fa-arrow-left"></i> <span className='ms-2 fs-3'  >Nouvelle photo</span> 
                                </Link>
							</div>
						</div>
					</nav>
					<br /> <br /> <br /> 
					<div className='p-2'>
							<div className="box-features p-4 mt-2 bg-transparent">
                               
                                <form onSubmit={handleSubmit}>
                           
                                   
                                    <div className='row'>
                                        <div className="mb-3">
                                            <h5 className="float-start fw-bolder" style={{ color: '#C84C47', fontFamily: 'tahoma' }}>
                                                <i class="fa-solid fa-bed me-2"></i>:  {id}
                                            </h5>
                                        </div>
                                    </div>
                                  
                                    <br /> 
                                
                                    <div className="mb-4 mt-3" style={{ width: '100%' }}>
                                        <div
                                            className="bg-danger d-flex align-items-center justify-content-center"
                                            style={{
                                            width: '150px',
                                            height: '140px',
                                            borderRadius: '9%',
                                            position: 'relative',
                                            }}
                                        >
                                            {/* Image */}
                                            <img
                                            id="imageView"
                                            src={imageURL || `${process.env.PUBLIC_URL}/logo512.jpg`}
                                            alt="default"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                borderRadius: '9%',
                                                objectFit: 'cover', // Ajuste l'image pour qu'elle remplisse le conteneur
                                            }}
                                            />

                                            {/* Icône caméra */}
                                            <Link
                                            onClick={openPicker}
                                            style={{
                                                position: 'absolute',
                                                bottom: '10px',
                                                right: '10px',
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fond semi-transparent
                                                borderRadius: '20%',
                                                padding: '7px',
                                                color: '#fff',
                                            }}
                                            >
                                            <i className="fa fa-camera" style={{ fontSize: '18px' }}></i>
                                            </Link>
                                        </div>
                                    </div>
                                    
                                
                                    <div className="mb-3">
                                        <label className="float-start fw-bold text-light">Description</label>
                                        <input
                                            type="text"
                                            name="quartier"
                                            value={formData.description}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="Ex: Face petit terrain"
                                            required
                                        />
                                    </div>
                                   
                                    
                                    <button type="submit" className="go mt-3" >
                                        {loading ? (
                                            <div className="text-light">
                                                <Spinner as="span" aria-hidden="true" animation="border" size="sm" variant="light" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                                <span className="ms-1">Chargement...</span>
                                            </div>
                                        ) : (
                                            'Ajouter'  
                                        )} 
                                    </button>
                                    <div className="text-success fw-normal mt-1" style={{ height: '20px'}}>
                                    {message && <p>{message}</p>}
                                    </div>
                                </form>
							</div>
					</div>
			    </>
            ) : (
			    <>
				    <div className='App-Container'>
						<div className="text-center">
							<h2 className="text-danger">Erreur: Informations de l'application non disponibles</h2>
						</div>
			        </div>
				</>
            )}	   
        </div>     
    )
};

export default Create;
